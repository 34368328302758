import {AUTH} from "../auth/FirebaseContext";
import axios from "axios";

const BSS_URL = "https://europe-central2-esir-44ade.cloudfunctions.net/bssAPI"

const customAxios = (token, customConfig = {}, customReponseType = {}) => axios.create({
    baseURL: BSS_URL,
    ...customReponseType,
    headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
        ...customConfig
    },
});

export async function addNewInvoiceApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post("admin/addNewInvoice", data);
}

export async function fetchTenantsFinancialReportApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/admin/tenantSummary`,data);
}

export async function fetchTenantActivationsApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/admin/tenantActivation`,data);
}

export async function blockPayment(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/admin/blockPayment`,data);
}

export async function takeAwayPayment(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`admin/removePaymentAllocation`, data);
}

export async function getUserCardDetailsApi(data){
    const token = await AUTH.currentUser.getIdToken(true);
    if (data.tin!==undefined) {
        if (data.type === "ODS") {
            return customAxios(token, {}, { responseType: 'arraybuffer' }).get(`admin/userTransactions?TIN=${data.tin}&type=${data.type}`)
        }
        return customAxios(token).get(`admin/userTransactions?TIN=${data.tin}`)
    }else if (data.uid!==undefined){
        if (data.type === "ODS") {
            return customAxios(token, {}, { responseType: 'arraybuffer' }).get(`admin/userTransactions?uid=${data.uid}&type=${data.type}`)
        }
        return customAxios(token).get(`admin/userTransactions?uid=${data.uid}`)
    }
    if (data.type === "ODS") {
        return customAxios(token, {}, { responseType: 'arraybuffer' }).get(`admin/userTransactions?type=${data.type}`)
    }
    return customAxios(token).get(`admin/userTransactions?`)
}

export async function uploadXMLPaymentsApi(xmlFile){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token, { 'Content-Type': 'application/xml' }).post(`admin/uploadPayments`,xmlFile);
}

export async function generateUserReport(uid, from, to){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).get(`admin/report/generateUserReports?uid=${uid}&from=${from}&to=${to}`)
}

export async function sendEmail(data){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`util/sendMail`, data)
}

export async function invoiceCancellation(data){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`admin/cancelInvoice`, data)
}

export async function invoiceDeletion(data){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`admin/deleteInvoice`, data)
}

export async function updateBusinessApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).put(`admin/business`, data);
}

export async function insertLocation(location) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/certificate`, location);
}

export async function insertOperator(operator) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/operator`, operator);
}

export async function getClientInfo(tin) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).get(`/getCompanyDetails?TIN=${tin}`);
}

export async function getAllClients(startAfter, limitToFirst) {
    let params = "?";
    if (startAfter && startAfter !== "") {
        params = params + `startAfter=${startAfter}`
    }
    if (limitToFirst && !isNaN(limitToFirst)) {
        params = params + `&limitToFirst=${limitToFirst}`
    }
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).get(`/clients${params}`);
}

export async function insertSeller(seller) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post("/user", seller);
}

export async function deleteClientApi(uid) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).delete("/user", {
        data: {
            uid: uid
        }
    });
}

export async function findUsersForPaymentApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post("/findUsers", data);
}
export async function assignPaymentToUserApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post("/admin/assignPaymentToUser", data);
}

export async function deleteUserPaymentApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/admin/resolvePayment`, data);
}

export async function requestPasswordReset(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/user/passwordReset`, data);
}

export async function deleteUserCertificate(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).delete(`/certificate`, {
        data: data
    });
}

export async function findClient(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/findUsers`, data);
}

export async function extendLicense(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/admin/temporaryExtendLicense`, data);
}

export async function sendMessageApi(data) {
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/admin/message`, data);
}

export async function getAllFbUsersApi(startAfter, limitToFirst) {
    let params = "?showAll=true";
    if (startAfter && startAfter !== "") {
        params = params + `&startAfter=${startAfter}`
    }
    if (limitToFirst && !isNaN(limitToFirst)) {
        params = params + `&limitToFirst=${limitToFirst}`
    }
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).get(`/clients${params}`);
}

export async function getLicenseHistory(uid){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).get(`/license?uid=${uid}&history=false`);
}

export async function changeNumberOfLicenceApi(data){
    const token = await AUTH.currentUser.getIdToken(true);
    return customAxios(token).post(`/license`, data);
}
