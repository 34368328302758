import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {child, get} from "firebase/database";
import {dbRef} from "../../auth/FirebaseContext";


export const fetchAllContractTemplates = createAsyncThunk("fetchAllContractTemplates", async () => {
  let arr = [];
  (await get(child(dbRef, `bss/public/contractTemplates`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      id: parseInt(child1.key),
      enabled: child1.val().enabled === undefined ? true : child1.val().enabled
    });
  });
  return arr;
});
export const fetchAlleFactureContractTemplates = createAsyncThunk("fetchAlleFactureContractTemplates", async () => {
  let arr = [];
  (await get(child(dbRef, `bss/public/ContractTemplatesEFakt`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      id: parseInt(child1.key),
      enabled: child1.val().enabled === undefined ? true : child1.val().enabled
    });
  });
  return arr;
});

export const fetchAllFactureOperatorsGroup = createAsyncThunk("fetchAllFactureOperatorsGroup", async () => {
  let arr = [];
  (await get(child(dbRef, `/public/groupsFakture`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      id: parseInt(child1.key),
      isFacture: true
    });
  });
  return arr;
});

export const fetchAllOperatorsGroups = createAsyncThunk("fetchAllOperatorsGroups", async (data) => {
  let all = [];
  (await get(child(dbRef, "public/groups"))).forEach(child1 => {
    all.push({
      ...child1.val(),
      uid: parseInt(child1.key),
      group: "Esir"
    });
  });
  (await get(child(dbRef, `public/groupsFakture`))).forEach(child1 => {
    all.push({
      ...child1.val(),
      uid: parseInt(child1.key),
      group: "Fakture"
    });
  });
  return all;
});

export const fetchAllPublicLicense = createAsyncThunk("fetchAllPublicLicense", async () => {
  let arr = [];
  (await get(child(dbRef, `public/products`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      id: child1.key
    });
  });
  return arr;
});

export const fetchAllItemTemplates = createAsyncThunk("fetchAllItemTemplates", async () => {
  let arr = [];
  (await get(child(dbRef, `bss/public/itemTemplates`))).forEach(child1 => {
    arr.push({
      ...child1.val(),
      id: child1.key
    });
  });
  return arr;
});

const initialState = {
  contracts: [],
  itemTemplates: [],
  factureGroups: [],
  eFactureContracts: [],
  operatorGroups: [],
  publicLicense: [],
  loading: true
};

export const slice = createSlice({
  name: "nomenclatures",
  initialState,
  reducers: {},
  extraReducers: {
    // fetchAlleFactureContractTemplates
    [fetchAlleFactureContractTemplates.pending]: (state) => {
      state.loading = true;
    },
    [fetchAlleFactureContractTemplates.fulfilled]: (state, { payload }) => {
      state.eFactureContracts = payload;
      state.loading = false;
    },
    [fetchAlleFactureContractTemplates.rejected]: (state) => {
      state.loading = false;
    },
    // fetchAllContractTemplates
    [fetchAllContractTemplates.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllContractTemplates.fulfilled]: (state, { payload }) => {
      state.contracts = payload;
      state.loading = false;
    },
    [fetchAllContractTemplates.rejected]: (state) => {
      state.loading = false;
    },
    // fetchAllOperatorsGroups
    [fetchAllOperatorsGroups.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllOperatorsGroups.fulfilled]: (state, { payload }) => {
      state.operatorGroups = payload;
      state.loading = false;
    },
    [fetchAllOperatorsGroups.rejected]: (state) => {
      state.loading = false;
    },
    // fetchAllPublicLicense
    [fetchAllPublicLicense.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllPublicLicense.fulfilled]: (state, { payload }) => {
      state.publicLicense = payload;
      state.loading = false;
    },
    [fetchAllPublicLicense.rejected]: (state) => {
      state.loading = false;
    },
    // fetchAllFactureOperatorsGroup
    [fetchAllFactureOperatorsGroup.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllFactureOperatorsGroup.fulfilled]: (state, { payload }) => {
      state.factureGroups = payload;
      state.loading = false;
    },
    [fetchAllFactureOperatorsGroup.rejected]: (state) => {
      state.loading = false;
    },
    // fetchAllItemTemplates
    [fetchAllItemTemplates.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllItemTemplates.fulfilled]: (state, { payload }) => {
      state.itemTemplates = payload;
      state.loading = false;
    },
    [fetchAllItemTemplates.rejected]: (state) => {
      state.loading = false;
    }
  }
});
export default slice.reducer;


